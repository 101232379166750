import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';
import { COUNTRY_BASE_FRAGMENT } from '@/graphql/_Fragments/Country/Base';
import { ADDRESS_BASE_FRAGMENT } from '@/graphql/_Fragments/Address/Base';
import { HANDOUT_BASE_FRAGMENT } from '@/graphql/_Fragments/Handout/Base';
import { COMMUNITY_USER_TAG_BASE_FRAGMENT } from '@/graphql/_Fragments/CommunityUserTag/Base';
import { DEAL_BASE_FRAGMENT } from '@/graphql/_Fragments/Deal/Base';
import { ARTICLE_BASE_FRAGMENT } from '@/graphql/_Fragments/Article/Base';
import { PRODUCT_BASE_FRAGMENT } from '@/graphql/_Fragments/Product/Base';
import { EXHIBITOR_URL_BASE_FRAGMENT } from '@/graphql/_Fragments/ExhibitorUrl/Base';
import { BOOTH_BASE_FRAGMENT } from '@/graphql/_Fragments/Booth/Base';
import CategoryDomainContext from '@/utils/enums/CategoryDomainContext';
import { CATEGORY_WITH_CONTEXT_FRAGMENT } from '@/graphql/_Fragments/Category/WithContext';

export const EXHIBITOR_FULL_FRAGMENT = `
  fragment exhibitorFullFragment on Exhibitor {
    uid
    schemaCode
    name
    displayName
    description
    featured
    mainExhibitor
    videoPresentationS3Url
    _maxSalesPackageRank
    logoFileResource {
      ...fileResourceBaseFragment
    }
    bannerFileResource {
      ...fileResourceBaseFragment
    }
    handouts {
      ...handoutBaseFragment
    }
    country {
      ...countryBaseFragment
    }
    addresses {
      ...addressBaseFragment
    }
    urls {
      ...exhibitorUrlBaseFragment
    }
    deals {
      ...dealBaseFragment
    }
    articles {
      ...articleBaseFragment
    }
    products {
      ...productBaseFragment
    }
    categories: categoriesInContext(context: "${CategoryDomainContext.COMPANY_CMS}" orderBy: [displaySequence_asc, name_asc]) {
      ...categoryWithContextFragment
    }
    boothsOfEdition: _boothsOfEdition {
      ...boothBaseFragment
    }
    _myTags(myUid: "%authUser%") {
      ...communityUserTagBaseFragment
    }
    _translations {
      locale
      values {
        key
        value
      }
    }
    _isBookmarked(myUid: "%authUser%")
    _isFollowed(myUid: "%authUser%")
    _isVisited(myUid: "%authUser%")
    _followerCount
    _bookmarkCount
    _effectiveLimitNumProducts
    _effectiveLimitNumLargeProducts
    _effectiveLimitNumDeals
    _effectiveLimitNumHandouts
    _effectiveLimitNumLogos
    _effectiveLimitNumBanners
    _effectiveLimitNumCompanyRoles
    _effectiveLimitNumCategories
    _effectiveLimitNumDescription
    _effectiveLimitNumImagePerProduct
    _effectiveLimitNumHandoutPerProduct
    _effectiveLimitNumImagePerLargeProduct
    _effectiveLimitNumHandoutPerLargeProduct
    _effectiveLimitNumContactUs
  }
  ${COMMUNITY_USER_TAG_BASE_FRAGMENT}
  ${CATEGORY_WITH_CONTEXT_FRAGMENT}
  ${FILE_RESOURCE_BASE_FRAGMENT}
  ${EXHIBITOR_URL_BASE_FRAGMENT}
  ${PRODUCT_BASE_FRAGMENT}
  ${COUNTRY_BASE_FRAGMENT}
  ${ADDRESS_BASE_FRAGMENT}
  ${HANDOUT_BASE_FRAGMENT}
  ${ARTICLE_BASE_FRAGMENT}
  ${BOOTH_BASE_FRAGMENT}
  ${DEAL_BASE_FRAGMENT}
`;
